import React, {useState} from 'react';
import {Link} from "react-router-dom";
import LogoAvalanche from '../../assets/imgs/head-logo-2.svg';
import {Tooltip} from "@mui/material";

const HeaderLogoComponent = () => {

    return(
        <div
            style={{
                marginTop: 50,
            }}
        >
            <Link to={"/"}>
                <Tooltip title={'Volver a la página principal'}>
                    <img
                        style={{
                            maxWidth: '100%',
                            maxHeight: '200px',
                        }}
                        src={LogoAvalanche}
                        alt={'Logo'}
                    />
                </Tooltip>
            </Link>
        </div>
    );
}

export default HeaderLogoComponent;
