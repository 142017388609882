const listThemesNfts = {
    'proyectos_hispanos': [
        {
            'title': 'Artcrypted',
            'identifier': 'artcrypted',
            'img': 'https://static.arteinformado.com/resources/app/docs/organizacion/65/134765/artcrypted_imagotipo_sin_fondo.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/proyectos_hispanos/artcrypted',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Blockpay',
            'identifier': 'blockpay',
            'img': 'https://static.wixstatic.com/media/26a1e6_601d703cc3294557833779ab440e359e~mv2.png/v1/fill/w_357,h_262,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Objeto%20inteligente%20vectorial.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/proyectos_hispanos/blockpay',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Crypto Munch',
            'identifier': 'crypto_munch',
            'img': require('../assets/imgs/nfts/crypto_munch.png'),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/proyectos_hispanos/crypto_munch',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
};

export default listThemesNfts;
