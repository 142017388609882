import React from "react";
import HeaderStatsComponent from "../HeaderStats";
import HeaderLogoComponent from "../HeaderLogo";

const Header = () => {
    return (
        <header>
            <HeaderStatsComponent/>
            <HeaderLogoComponent/>
        </header>
    );
}

export default Header;