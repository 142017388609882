import React, {useState} from 'react';
import {Grid} from "@mui/material";
import listAll from "../../utils/listAll";
import ListItemButton from "@mui/material/ListItemButton";
import CardTitle from "../CardTitle";

const GameFiComponent = () => {

    return(
        <div
            style={{
                marginBottom: '50px',
                padding: '20px'
            }}
        >
            <p
                style={{
                    textAlign: 'center',
                    fontSize: '1.3rem',
                    fontWeight: '500'
                }}
            >
                <img src={require('../../assets/imgs/red-triangle-icon-10.png')} alt="*"/> GameFi
            </p>
            <Grid
                container
                style={{
                    marginLeft: '75px',
                    marginRight: '75px',
                    width: 'auto',
                    marginTop: '50px'
                }}
                className={'headlines-text'}
            >
                <Grid
                    container
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}
                >
                    {
                        listAll.gamefi.map((theme, index) => {
                            return (
                                <Grid
                                    key={index}
                                    item xs={12} md={3}
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <ListItemButton
                                        style={{
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '10px',
                                            width: '100%',
                                        }}
                                    >
                                        <CardTitle
                                            title={theme}
                                            inDialog={false}
                                        />
                                    </ListItemButton>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default GameFiComponent;
