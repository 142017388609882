const listThemesDefi = {
    'exchanges_descentralizados': [
        {
            'title': 'Trader Joe',
            'identifier': 'trader_joe',
            'img': 'https://assets.rabbithole.gg/projects/trader-joe.jpeg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/exchanges_descentralizados/trader_joe',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Pangolin',
            'identifier': 'pangolin',
            'img': 'https://2781431973-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FWzSJAxFJ5SZEJTPvvyAw%2Fuploads%2FLkzPevHEBcGQDhHXbe8x%2FPangolin%20Logo%205.svg?alt=media&token=86080139-e323-4630-92eb-24bae74910b6',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/exchanges_descentralizados/pangolin',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Dexalot',
            'identifier': 'dexalot',
            'img': 'https://pbs.twimg.com/profile_images/1504536012554485761/9NQj3iQI_400x400.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/exchanges_descentralizados/dexalot',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Platypus',
            'identifier': 'platypus',
            'img': 'https://platypus.finance/logo-platypus-v2-icon-only.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/exchanges_descentralizados/platypus',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'GMX',
            'identifier': 'gmx',
            'img': 'https://gmx.io/og.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/exchanges_descentralizados/gmx',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
    'prestamos_p2p': [
        {
            'title': 'Aave',
            'identifier': 'aave',
            'img': 'https://cryptologos.cc/logos/aave-aave-logo.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/prestamos_p2p/aave',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'BENQI',
            'identifier': 'benqi',
            'img': 'https://benqi.fi/images/assets/qi.svg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/prestamos_p2p/benqi',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Trader Joe',
            'identifier': 'trader_joe',
            'img': 'https://traderjoexyz.com/static/media/logo.bc60f78d.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/prestamos_p2p/trader_joe',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
    'agregadores_de_rendimiento': [
        {
            'title': 'Yield Yak',
            'identifier': 'yield_yak',
            'img': 'https://miro.medium.com/max/3150/1*iWF5-1eTwgUbUMDceMC3SQ.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/agregadores_de_rendimiento/yield_yak',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
    'puentes': [
        {
            'title': 'Avalanche Bridge',
            'identifier': 'avalanche_bridge',
            'img': 'https://bridge.avax.network/bridge.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/puentes/avalanche_bridge',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Core Wallet',
            'identifier': 'core_wallet',
            'img': 'https://pbs.twimg.com/profile_images/1539616954708803585/L_eUNTpQ_400x400.jpg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/puentes/core_wallet',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'LayerZero',
            'identifier': 'layerzero',
            'img': 'https://cryptodailycdn.ams3.cdn.digitaloceanspaces.com/layerzero.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/puentes/layerzero',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Celer Network',
            'identifier': 'celer_network',
            'img': 'https://www.celer.network/static/Black-4d795924d523c9d8d45540e67370465a.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/puentes/celer_network',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
    'memecoins': [
        {
            'title': 'Tether (USDT)',
            'identifier': 'usdt',
            'img': 'https://cryptologos.cc/logos/tether-usdt-logo.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/memecoins/usdt',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'USD Coin (USDC)',
            'identifier': 'usdc',
            'img': 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/memecoins/usdc',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Shiba Inu (SHIB)',
            'identifier': 'shiba_inu',
            'img': 'https://seeklogo.com/images/S/shiba-inu-shib-logo-9542F950B0-seeklogo.com.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/memecoins/shiba_inu',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Husky AVAX (HUSKY)',
            'identifier': 'husky_avax',
            'img': 'https://pbs.twimg.com/profile_images/1544403344046886914/E2mNUosA_400x400.jpg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/memecoins/husky_avax',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
    'pagos': [
        {
            'title': 'Cryptorefills',
            'identifier': 'cryptorefills',
            'img': 'https://fintechnavigator.org/wp-content/uploads/2020/09/cryptorefills-logo.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/defi/pagos/cryptorefills',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
};

export default listThemesDefi;
