const listThemesNfts = {
    'exploradores': [
        {
            'title': 'Avascan',
            'identifier': 'avascan',
            'img': 'https://avascan.info/cdn/avascan-summary.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/exploradores/avascan',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Snowtrace',
            'identifier': 'snowtrace',
            'img': ' https://dexnav.com/wp-content/uploads/2021/12/main.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/exploradores/snowtrace',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Avalanche Explorer',
            'identifier': 'avalanche_explorer',
            'img': 'https://assets-global.website-files.com/6059b554e81c705f9dd2dd32/61117e64db9a2a7222b8af82_Avalanche_Circle_Black_Trans.svg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/exploradores/avalanche_explorer',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
};

export default listThemesNfts;
