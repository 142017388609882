import React from 'react';
import {Grid, ListItemButton} from "@mui/material";
import listAll from "../../utils/listAll";
import CardTitle from "../CardTitle";

const WalletsComponent = () => {

    return(
        <div
            style={{
                marginBottom: '50px',
                padding: '20px'
            }}
        >
            <p
                style={{
                    textAlign: 'center',
                    fontSize: '1.3rem',
                    fontWeight: '500'
                }}
            >
                <img
                    src={require('../../assets/imgs/red-triangle-icon-10.png')}
                    alt="*"
                    style={{
                        marginRight: '10px',
                        marginBottom: '1px'
                    }}
                />
                Wallets
            </p>

            <Grid
                container
                style={{
                    width: '100%',
                    marginTop: '50px',
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
                className={'headlines-text'}
            >
                {
                    listAll.wallets.map((theme, index) => {
                        return (
                            <Grid
                                key={index}
                                item xs={12} md={3}
                                style={{
                                    width: '100%',
                                }}
                            >
                                <ListItemButton
                                    style={{
                                        textAlign: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '10px',
                                        width: '100%',
                                    }}
                                >
                                    <CardTitle
                                        title={theme}
                                        inDialog={true}
                                    />
                                </ListItemButton>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </div>
    );
}

export default WalletsComponent;
