const listThemesNfts = {
    'wallets': [
        {
            'title': 'Avalanche',
            'identifier': 'avalanche',
            'img': 'https://wallet.avax.network/img/diamond-primary.6818c3c7.svg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/wallets/avalanche',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Metamask',
            'identifier': 'metamask',
            'img': 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/wallets/metamask',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Core Wallet',
            'identifier': 'core_wallet',
            'img': 'https://pbs.twimg.com/profile_images/1539616954708803585/L_eUNTpQ_400x400.jpg ',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/wallets/core_wallet',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
};

export default listThemesNfts;
