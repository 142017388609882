const listThemesGamefi = {
    'gamefi': [
        {
            'title': 'Crabada',
            'identifier': 'crabada',
            'img': 'https://cdn.techinasia.com/wp-content/uploads/2022/03/1648116323_Crabada.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/gamefi/crabada',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Shrapnel',
            'identifier': 'shrapnel',
            'img': 'https://venturebeat.com/wp-content/uploads/2022/04/SHRAPNEL_KEYART_DT.jpg?w=1200&strip=all',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/gamefi/shrapnel',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Ascenders',
            'identifier': 'ascenders',
            'img': 'https://pbs.twimg.com/media/FMsuVgqXMAI7OqL.jpg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/gamefi/ascenders',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
};

export default listThemesGamefi;
