import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link, BrowserRouter, HashRouter
} from 'react-router-dom';
import './App.css';
import HomeScreenComponent from "./components/HomeScreen";
import HeaderLogoComponent from "./components/HeaderLogo";
import HeaderStatsComponent from "./components/HeaderStats";
import SocialFooterComponent from "./components/SocialFooter";
import IntroComponent from "./components/IntroComponent";
import Empezar from "./components/Empezar";
import Wallets from "./components/Wallets";
import Exploradores from "./components/Exploradores";
import DeFi from "./components/DeFi";
import NFTs from "./components/NFTs";
import GameFi from "./components/GameFi";
import SubTheme from "./components/SubTheme";
import SubnetsComponent from "./components/Subnets";
import ProyectosHispanos from "./components/ProyectosHispanos";
import Header from "./components/Header";


function App() {
    return (
        <HashRouter>
            <div className="App">
                <Header />

                <Routes>
                    <Route path="/" element={<HomeScreenComponent/>}/>
                    <Route path="/intro" element={<IntroComponent />}/>
                    <Route path="/empezar" element={<Empezar />}/>
                    <Route path="/wallets" element={<Wallets />}/>
                    <Route path="/exploradores" element={<Exploradores />}/>

                    <Route path="/:category/:theme" element={<SubTheme />}/>

                    <Route path="/defi" element={<DeFi />}/>
                    <Route path="/defi/:category/:theme" element={<SubTheme />}/>

                    <Route path="/nfts" element={<NFTs />}/>
                    <Route path="/nfts/:category/:theme" element={<SubTheme />}/>

                    <Route path="/gamefi" element={<GameFi />}/>
                    <Route path="/gamefi/:category/:theme" element={<SubTheme />}/>

                    <Route path="/subnets" element={<SubnetsComponent />}/>
                    <Route path="/subnets/:category/:theme" element={<SubTheme />}/>

                    <Route path="/proyectos_hispanos" element={<ProyectosHispanos />}/>
                </Routes>

                <SocialFooterComponent/>
            </div>
        </HashRouter>
    );
}

export default App;
