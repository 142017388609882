import React, {useEffect, useState} from 'react';
import "./socialfooter.css";
import {Facebook, Instagram, Twitter, YouTube} from "@mui/icons-material";
import {Grid} from "@mui/material";
import MetamaskLogo from "../../assets/imgs/metamask-logo.png";

const SocialFooterComponent = () => {

    const AVALANCHE_MAINNET_PARAMS = {
        chainId: '0xA86A',
        chainName: 'Avalanche C-Chain',
        nativeCurrency: {
            name: 'Avalanche',
            symbol: 'AVAX',
            decimals: 18
        },
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://snowtrace.io/']
    }

    const AVALANCHE_TESTNET_PARAMS = {
        chainId: '0xA869',
        chainName: 'Avalanche FUJI C-Chain',
        nativeCurrency: {
            name: 'Avalanche',
            symbol: 'AVAX',
            decimals: 18
        },
        rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://testnet.snowtrace.io/']
    }

    const prod = 'true';

    const POLYGON_NETWORK_PARAMS = prod === 'true' ? AVALANCHE_MAINNET_PARAMS : AVALANCHE_TESTNET_PARAMS;

    const switchToPolygonChain = () => {
        window.ethereum.autoRefreshOnNetworkChange = true;
        window.ethereum
            .request({
                method: 'wallet_addEthereumChain',
                params: [POLYGON_NETWORK_PARAMS]
            });
    }

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.autoRefreshOnNetworkChange = true;
        }
    }, []);

    return (
        <div
            className={'bottom-footer'}
        >
            <Grid
                container
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
            >
                <Grid
                    item xs={6} md={5}
                    style={{
                        width: '100%',
                    }}
                >
                    <div
                        className={'bottom-footer-title'}
                    >
                        Redes sociales
                    </div>

                    <div
                        className={'bottom-footer-social'}
                    >
                        <a href={"https://twitter.com/todosobreavax"} target={'_blank'}><Twitter/></a>
                        <a href={"https://www.youtube.com/channel/UCdLqdrMtJF2WYiHLNiMyLKA"} target={'_blank'}><YouTube/></a>
                        <a href={"https://www.instagram.com/todosobreavalanche/"} target={'_blank'}><Instagram/></a>
                        <a href={"https://www.facebook.com/groups/639766104017635"} target={'_blank'}><Facebook/></a>
                    </div>
                </Grid>

                <Grid
                    item xs={5} md={5}
                    style={{
                        width: '100%',
                    }}
                >
                    <div
                        className={'bottom-footer-title'}
                    >
                        Agregar Avalanche C-Chain
                    </div>

                    <div
                        className={'bottom-footer-social'}
                    >
                        <a>
                            <img
                                src={MetamaskLogo}
                                alt={'Metamask'}
                                className={'logo-metamask'}
                                onClick={switchToPolygonChain}
                            />
                        </a>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default SocialFooterComponent;
