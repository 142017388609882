import React, {useEffect, useState} from "react";
import {Container, Grid, IconButton, Tooltip, useMediaQuery} from "@mui/material";
import CardTitle from "../CardTitle";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import listAll from "../../utils/listAll";

const SubTheme = () => {

    const sm = useMediaQuery('(max-width:899px)');
    const {category, theme} = useParams();

    const [listTitles, setListTitles] = useState([]);
    const [title, setTitle] = useState(null);

    useEffect(() => {
        setListTitles(listAll[category]);
        console.log('category', category);
        console.log('listTitles', listTitles);
    }, [listAll]);

    useEffect(() => {
        setTitle(listTitles.find(item => item.identifier === theme));
        console.log('theme', theme);
        console.log('title', title);
    }, [listTitles])

    return (
        <Container
            maxWidth="xl"
            className={'container-titles-video'}
        >
            <Grid
                container
                spacing={sm ? 0 : 3}
                style={{
                    width: '100%',
                    marginTop: '0px',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
                className={'headlines-text'}
            >
                <Grid
                    item xs={12} md={12}
                    style={{
                        marginBottom: '30px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title={'Atrás'}>
                        <IconButton
                            size={'small'}
                            style={{
                                border: '1px solid #00000022',
                                marginRight: '10px',
                                color: '#000000',
                            }}
                            onClick={() => {
                                window.history.back();
                            }}
                        >
                            <ArrowBack/>
                        </IconButton>
                    </Tooltip>

                    <span
                        style={{
                            marginBottom: '5px',
                            fontWeight: '600',
                            letterSpacing: '1px',
                        }}
                    >
                        {
                            (title !== null && title !== undefined) ? title.title : 'Loading...'
                        }
                    </span>
                </Grid>

                {
                    (title !== null && title !== undefined && title['gotoUrl']['isVideo'] === true) &&
                    <Grid
                        item xs={12} md={6}
                    >
                        <iframe
                            src={"https://www.youtube.com/embed/" + title.embed}
                            title="Todo Sobre Avalanche" frameBorder="0"
                            style={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '500px',
                                maxWidth: '700px',
                                minHeight: sm ? '250px' : '400px',
                            }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    </Grid>
                }

            </Grid>
        </Container>
    )
}

export default SubTheme;
