const listThemesNfts = {
    'subnets': [
        {
            'title': 'Todo sobre Subnets',
            'identifier': 'todo_sobre_subnets',
            'img': 'https://assets.website-files.com/6059b554e81c705f9dd2dd32/6286699fbe1feece7019bf1e_Subnets_OG.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/subnets/todo_sobre_subnets',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Swimmer Network',
            'identifier': 'swimmer_network',
            'img': 'https://pbs.twimg.com/media/FM_hUEhVQAEpbCC?format=jpg&name=4096x4096',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/subnets/swimmer_network',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'DeFi Kingdoms Blockchain (DFK Chain)',
            'identifier': 'defi_kingdoms_blockchain',
            'img': 'https://miro.medium.com/max/1200/1*6LGv2JaxfG4sUuJO5AfUPQ.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/subnets/defi_kingdoms_blockchain',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Dexalot Subnet',
            'identifier': 'dexalot_subnet',
            'img': 'https://miro.medium.com/max/1200/1*hXJOjREcfIMopeei-WXauw.jpeg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/subnets/dexalot_subnet',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
};

export default listThemesNfts;
