import React from "react";
import {Card, CardActionArea, CardMedia, Dialog, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

const CardTitle = ({title, inDialog}) => {

    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = React.useState(false);

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <iframe
                    width="560" height="315" src={"https://www.youtube.com/embed/" + title.embed}
                    title="Todo Sobre Avalanche" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            </Dialog>
            <Card
                elevation={3}
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <CardActionArea
                    className={'container-video'}
                    onClick={() => {
                        if (inDialog) {
                            setOpenDialog(true);
                        } else {
                            if (title.gotoUrl.goto === true) {
                                navigate(title.gotoUrl.url);
                            } else {
                                window.open(title.video, '_blank');
                            }
                        }
                    }}
                >
                    <Typography
                        className={'video-container-title'}
                    >
                        {
                            title.title
                        }
                    </Typography>
                    <CardMedia
                        component="img"
                        image={title.img}
                        alt="Thumbnail"
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '150px',
                            maxHeight: '150px',
                            minWidth: '150px',
                            minHeight: '150px',
                            borderRadius: '15px',
                            objectFit: 'fill',
                        }}
                    />
                </CardActionArea>
            </Card>
        </>
    )
}

export default CardTitle;
