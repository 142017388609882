import React, {useState} from 'react';

const HeaderStatsComponent = () => {

    return(
        <div
            style={{
                backgroundColor: "#F3F3F3",
                width: '100%',
                height: '40px',
            }}
        >
            <coingecko-coin-price-marquee-widget  coin-ids="avalanche-2,gmx,joe,pangolin,benqi,avalaunch,platypus-finance,yield-yak,chikn-egg,crabada,treasure-under-sea,defi-kingdoms" currency="usd" background-color="#ffffff" locale="en"></coingecko-coin-price-marquee-widget>
        </div>
    );
}

export default HeaderStatsComponent;
