import React from 'react';
import {Grid} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import './homescreen.css'
import {useNavigate} from "react-router-dom";

const HomeScreenComponent = () => {

    const navigate = useNavigate();

    return (
        <div
            style={{
                marginBottom: '70px',
                padding: '20px',
            }}
        >
            <div className={"regularText"}>
                <div
                    className={'info-text'}
                >
                    Toda la información que necesitas para dominar la blockchain de <b>Avalanche ($AVAX)</b> y su
                    ecosistema.
                </div>
                <br/>
                <span
                    style={{
                        background: 'black',
                        color: 'white',
                        padding: '5px 10px 8px 10px',
                        borderRadius: '2px',
                        fontWeight: '600',
                    }}
                >
                    Disponible gratis en español
                </span>
            </div>

            <Grid
                container
                style={{
                    width: 'auto',
                    marginTop: '50px',
                    marginBottom: '50px'
                }}
            >

                <Grid
                    item xs={12} md={4}
                    className={'home-screen-column'}
                >
                    <div className={"mainHeader"}
                    >
                        BÁSICO/INTERMEDIO
                    </div>

                    <List
                        style={{
                            width: '100%',
                            padding: '0px',
                        }}
                    >
                        <ListItem>
                            <ListItemButton
                                className={"list-button"}
                                onClick={() => {
                                    navigate('/intro')
                                }}
                            >
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Introducción"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                className={"list-button"}
                                onClick={() => {
                                    navigate('/empezar')
                                }}
                            >
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Cómo empezar"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton className={"list-button"}
                                            onClick={() => {
                                                navigate('/wallets')
                                            }}
                            >
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Wallets"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton className={"list-button"}
                                            onClick={() => {
                                                navigate('/exploradores')
                                            }}
                            >
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Exploradores"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>

                <Grid
                    item xs={12} md={4}
                    className={'home-screen-column'}
                >
                    <div className={"mainHeader"}
                    >
                        AVANZADO
                    </div>

                    <List
                        style={{
                            width: '100%',
                            padding: '0px',
                        }}
                    >
                        <ListItem>
                            <ListItemButton
                                className={"list-button"}
                                onClick={() => {
                                    navigate('/defi')
                                }}
                            >
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="DeFi"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton
                                className={"list-button"}
                                onClick={() => {
                                    navigate('/nfts')
                                }}
                            >
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="NFTs"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                className={"list-button"}
                                onClick={() => {
                                    navigate('/gamefi')
                                }}
                            >
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="GameFi"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                className={"list-button"}
                                onClick={() => {
                                    navigate('/subnets')
                                }}
                            >
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Subnets"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>

                <Grid
                    item xs={12} md={4}
                    className={'home-screen-column'}
                >
                    <div className={"mainHeader"}
                    >
                        EXTRA
                    </div>

                    <List
                        style={{
                            width: '100%',
                            padding: '0px',
                        }}
                    >
                        <ListItem>
                            <ListItemButton
                                className={"list-button"}
                                onClick={() => {
                                    navigate('/proyectos_hispanos')
                                }}
                            >
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Proyectos hispanos"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton
                                className={"list-button"}
                            >
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Preguntas frecuentes"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton className={"list-button"}>
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Últimas noticias"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton className={"list-button"}>
                                <ListItemIcon
                                    className={"list-icon"}
                                >
                                    <div className={"triangle-up"}></div>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Lanza tu proyecto"
                                    className={"list-text"}
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>

            </Grid>
        </div>
    );
}

export default HomeScreenComponent;
