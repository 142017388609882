const listThemesNfts = {
    'marketplaces': [
        {
            'title': 'Kalao',
            'identifier': 'kalao',
            'img': require("../assets/imgs/nfts/kalao.jpg"),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/nfts/marketplaces/kalao',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Campfire',
            'identifier': 'campfire',
            'img': require("../assets/imgs/nfts/campfire.jpg"),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/nfts/marketplaces/campfire',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Artcrypted',
            'identifier': 'artcrypted',
            'img': 'https://pbs.twimg.com/profile_images/1531756218380853248/XOdpKbDs_400x400.jpg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/nfts/marketplaces/artcrypted',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Joepegs',
            'identifier': 'joepegs',
            'img': 'https://pbs.twimg.com/profile_images/1541463779535364099/nCk38mDT_400x400.jpg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/nfts/marketplaces/joepegs',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
    'proyectos': [
        {
            'title': 'Avalanche Name Service',
            'identifier': 'avalanche_name_service',
            'img': 'https://avvy.domains/static/avvy/images/logo.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/nfts/proyectos/avalanche_name_service',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Party Animals',
            'identifier': 'party_animals',
            'img': 'https://miro.medium.com/max/911/1*_8eAwI4SVzG0iEo75AZLgQ.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/nfts/proyectos/party_animals',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'AVAX Apes',
            'identifier': 'avax_apes',
            'img': 'https://www.avaxapes.com/static/media/HomepageApe.120c09f9.png',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/nfts/proyectos/avax_apes',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Chikn Farm',
            'identifier': 'chikn_farm',
            'img': 'https://pbs.twimg.com/profile_images/1543587761419997184/4wfVpCum_400x400.jpg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/nfts/proyectos/chikn_farm',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Chad Doge',
            'identifier': 'chad_doge',
            'img': 'https://pbs.twimg.com/profile_images/1545473632524472320/GiPspxZv_400x400.jpg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/nfts/proyectos/chad_doge',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
        {
            'title': 'Friend Forest',
            'identifier': 'friend_forest',
            'img': 'https://pbs.twimg.com/profile_images/1522298011119890433/llqdBrSH_400x400.jpg',
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
            'gotoUrl': {
                'url': '/nfts/proyectos/friend_forest',
                'goto': true,
                'isVideo': true,
                'isBlog': false,
                'isSubTheme': false,
            },
        },
    ],
};

export default listThemesNfts;
