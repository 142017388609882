import React from 'react';
import {
    Container, Dialog,
    Grid,
    useMediaQuery
} from "@mui/material";
import CardTitle from "../CardTitle";

const IntroComponent = () => {

    const sm = useMediaQuery('(max-width:899px)');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [videoSelect, setVideoSelect] = React.useState('');

    const handleVideoSelect = (_value) => {
        setVideoSelect(_value);
        setOpenDialog(true);
    }

    const titles = [
        {
            'title': '¿Qué es Avalanche?',
            'img': require('../../assets/imgs/test-thumb.jpg'),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
        },
        {
            'title': '¿Cómo funciona Avalanche?',
            'img': require('../../assets/imgs/test-thumb.jpg'),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
        },
        {
            'title': '¿Por qué construir sobre Avalanche?',
            'img': require('../../assets/imgs/test-thumb.jpg'),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
        },
        {
            'title': '¿Web3 sobre Avalanche?',
            'img': require('../../assets/imgs/test-thumb.jpg'),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
            'embed': '9-rKvhsjwKU',
        },
    ];

    return (
        <Container
            maxWidth="xl"
            className={'container-titles-video'}
        >
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <iframe
                    width="560" height="315" src={"https://www.youtube.com/embed/" + videoSelect}
                    title="Todo Sobre Avalanche" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            </Dialog>

            <p
                style={{
                    textAlign: 'center',
                    fontSize: '1.3rem',
                    fontWeight: '500'
                }}
            >
                <img
                    src={require('../../assets/imgs/red-triangle-icon-10.png')}
                    alt="*"
                    style={{
                        marginRight: '10px',
                        marginBottom: '1px'
                    }}
                />
                Introducción
            </p>

            <Grid
                container
                spacing={sm ? 0 : 3}
                style={{
                    width: '100%',
                    marginTop: '50px',
                    marginBottom: '50px',
                }}
                className={'headlines-text'}
            >
                {
                    titles.map((title, index) => {
                        return (
                            <Grid
                                key={index}
                                item xs={12} md={3}
                                style={{
                                    marginBottom: sm ? '30px' : '0px',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                }}
                            >
                                <CardTitle
                                    title={title}
                                    inDialog={true}
                                    showDialog={handleVideoSelect}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Container>
    );
}

export default IntroComponent;
