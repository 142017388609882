import React, {useState} from 'react';
import {Container, Grid, useMediaQuery} from "@mui/material";
import CardTitle from "../CardTitle";

const EmpezarComponent = () => {

    const sm = useMediaQuery('(max-width:899px)');

    const titles = [
        {
            'title': '¿Dónde, cómo y por qué comprar AVAX?',
            'img': require('../../assets/imgs/test-thumb.jpg'),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
        },
        {
            'title': '¿Consejos de seguridad al utilizar criptomonedas y mintear NFTs?',
            'img': require('../../assets/imgs/test-thumb.jpg'),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
        },
        {
            'title': '¿Cómo enviar y guardar AVAX de manera segura?',
            'img': require('../../assets/imgs/test-thumb.jpg'),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
        },
        {
            'title': '¿Herramientas y dapps sobre Avalanche?',
            'img': require('../../assets/imgs/test-thumb.jpg'),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
        },
        {
            'title': '¿Conectando Metamask a Avalanche?',
            'img': require('../../assets/imgs/test-thumb.jpg'),
            'video': 'https://www.youtube.com/watch?v=9-rKvhsjwKU',
        },
        {
            'title': '¿A quienes seguir en Twitter sobre AVAX?',
            'img': require('../../assets/imgs/test-thumb.jpg'),
            'video': 'https://www.youtube.com/embed/XqQZQZQ',
        },
    ];

    return (
        <Container
            maxWidth="xl"
            className={'container-titles-video'}
        >
            <p
                style={{
                    textAlign: 'center',
                    fontSize: '1.3rem',
                    fontWeight: '500'
                }}
            >
                <img
                    src={require('../../assets/imgs/red-triangle-icon-10.png')}
                    alt="*"
                    style={{
                        marginRight: '10px',
                        marginBottom: '1px'
                    }}
                />
                Cómo empezar
            </p>

            <Grid
                container
                spacing={sm ? 0 : 3}
                style={{
                    width: '100%',
                    marginTop: '50px',
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginBottom: '50px',
                }}
                className={'headlines-text'}
            >
                {
                    titles.map((title, index) => {
                        return (
                            <Grid
                                key={index}
                                item xs={12} md={3}
                                style={{
                                    marginBottom: sm ? '30px' : '0px'
                                }}
                            >
                                <CardTitle
                                    title={title}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Container>
    );
}

export default EmpezarComponent;
