// Join all the arrays
import listThemesNfts from "./listThemesNfts";
import listThemesDefi from "./listThemesDefi";
import listThemesGamefi from "./listThemesGamefi";
import listThemesSubnets from "./listThemesSubnets";
import listThemesProyectosHispanos from "./listThemesProyectosHispanos";
import listThemesWallets from "./listThemesWallets";
import listThemesExploradores from "./listThemesExploradores";

const listAll = {
    ...listThemesNfts,
    ...listThemesDefi,
    ...listThemesGamefi,
    ...listThemesSubnets,
    ...listThemesProyectosHispanos,
    ...listThemesWallets,
    ...listThemesExploradores,
}

export default listAll;
